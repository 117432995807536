import React, { useContext, useState } from "react";
import { FormItem, InputTextFields, InputSelectField } from "../../components/InputField.styled";
import PropTypes from "prop-types";
import { RegisterStepContainer } from "../../components/Register.styled";
import { Form } from "antd";
import { NewCitizenContext } from "../NewCitizenContext";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { addBarangay } from "services/public_registration.service";
import { validators } from "utils/validators";
import { BARANGAY_RULES } from "constant/field";

const PersonalAddress = ({ children }) => {
  const {
    provinceOptions,
    municipalityOptions,
    barangayOption,
    setSelectedProvince,
    setSelectedMunicipality,
    selectedMunicipality,
    selectedProvince,
    stateDispatchMain,
    state,
    isLoading,
  } = useContext(NewCitizenContext);

  const [form] = Form.useForm();

  const [showAddBarangay, setShowAddBarangay] = useState(false);

  return (
    <RegisterStepContainer>
      <FormItem label="Probinsya" name="province" rules={[{ required: true, message: "Probinsya is required" }]}>
        <InputSelectField
          name="province"
          bordered={false}
          onChange={options => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                province: options,
              },
            });
            setSelectedProvince(options);
          }}
          options={provinceOptions}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </FormItem>
      <FormItem
        label="Lungsod/Munisipyo"
        name="municipality"
        rules={[{ required: true, message: "Lungsod/Munisipyo is required" }]}>
        <InputSelectField
          key="municipality"
          name="municipality"
          bordered={false}
          onChange={(_, option) => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                municipality: option?.value,
              },
            });
            setSelectedMunicipality(option?.value);
            form.setFieldsValue({ municipality: option });
          }}
          disabled={!selectedProvince}
          options={municipalityOptions}
          value={selectedMunicipality || null}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </FormItem>
      <div style={{ display: "flex" }}>
        <FormItem
          label="Barangay"
          name="barangay"
          style={{ marginRight: "1rem", width: "70%" }}
          rules={BARANGAY_RULES}>
          {showAddBarangay ? (
            <InputTextFields
              disabled={!selectedMunicipality}
              name="barangay"
              onChange={e =>
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    barangay: e.target.value,
                  },
                })
              }
              onBlur={async e => {
                if (e.target.value) {
                  const res = await addBarangay(e.target.value, selectedMunicipality);
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      barangay: res.id,
                      barangay_name: res.name,
                    },
                  });
                }
              }}
            />
          ) : (
            <InputSelectField
              name="barangay"
              allowClear
              bordered={false}
              onChange={(_, option) => {
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    barangay: option?.value,
                    barangay_name: option?.name,
                  },
                });
                form.setFieldsValue({ barangay: option });
              }}
              options={barangayOption}
              disabled={!selectedMunicipality}
              showSearch
              notFoundContent={!isLoading ? "Loading.." : "No Data"}
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            />
          )}
        </FormItem>
        <div
          style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "30%", textAlign: "center" }}>
          <div style={{ margin: "10px 0" }}>
            {showAddBarangay ? (
              <a>
                <CloseCircleOutlined
                  style={{ transform: "scale(1.5)" }}
                  onClick={() => setShowAddBarangay(curr => !curr)}
                />
              </a>
            ) : (
              <a>
                <PlusCircleOutlined
                  style={{ transform: "scale(1.5)" }}
                  onClick={() => setShowAddBarangay(curr => !curr)}
                />
              </a>
            )}
          </div>
          <div>
            {showAddBarangay ? (
              <p>Go back to list</p>
            ) : (
              <>
                <p>Add New Barangay</p>
              </>
            )}
          </div>
        </div>
      </div>
      <FormItem
        label="House No./Purok"
        name="address"
        rules={[{ required: true, message: "Address is required" }, validators.address]}>
        <InputTextFields
          name="address"
          onChange={e =>
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                address: e.target.value,
              },
            })
          }
        />
      </FormItem>
      {children}
    </RegisterStepContainer>
  );
};

PersonalAddress.propTypes = {
  formData: PropTypes.any,
  onInputChange: PropTypes.func,
  errors: PropTypes.any,
};

export default PersonalAddress;
