import { ENTITIES, entity } from "./entity";

const isAkapBigas = entity === ENTITIES.AKAP_BIGAS;
export const BARANGAY_RULES = [
  {
    message: "Barangay is required",
    required: isAkapBigas,
    validator: (_, value) => {
      if(!isAkapBigas) {
        return Promise.resolve();
      }
      if (!value || (typeof value === "string" && value.trim() === "")) {
        return Promise.reject(new Error("Barangay cannot be an empty string"));
      }
      return Promise.resolve();
    },
  },
];
