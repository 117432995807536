import React, { lazy, Suspense } from "react";
import { ENTITIES, entity } from "constant/entity";

const mapping = {
  [ENTITIES.AMPING_KANUNAY]: lazy(() =>
    import("components/ampingKanunay/logo").then(module => ({
      default: module.Logo,
    })),
  ),
  [ENTITIES.KAUBAN_NATO]: lazy(() =>
    import("components/kaubanNato/logo").then(module => ({
      default: module.Logo,
    })),
  ),
  [ENTITIES.BPSF]: lazy(() =>
    import("components/bpsfLogo").then(module => ({
      default: module.BpsfLogo,
    })),
  ),
  [ENTITIES.SERBISYONG_TINUD_ANAY]: lazy(() =>
    import("components/serbisyongTinudanay/logo").then(module => ({
      default: module.Logo,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("components/akapBigas/logo").then(module => ({
      default: module.Logo,
    })),
  ),
  [ENTITIES.SERBISYONG_LAMI_KAAYO]: lazy(() =>
    import("components/serbisyongLamiKaayo/logo").then(module => ({
      default: module.Logo,
    })),
  ),
  [ENTITIES.GWAPONG_SERBISYO]: lazy(() =>
    import("components/gwapongSerbisyo/logo").then(module => ({
      default: module.Logo,
    })),
  ),
  [ENTITIES.TINGOG_47]: lazy(() =>
    import("components/tingog47/logo").then(module => ({
      default: module.Logo,
    })),
  ),
  [ENTITIES.SERBISYONG_EXPRESS]: lazy(() =>
    import("components/serbisyongExpress/logo").then(module => ({
      default: module.Logo,
    })),
  ),
  [ENTITIES.OYO_UY]: lazy(() =>
    import("components/oyoUy/logo").then(module => ({
      default: module.Logo,
    })),
  ),
  [ENTITIES.MATIK_KANG_TINO]: lazy(() =>
    import("components/matikkangtino/logo").then(module => ({
      default: module.Logo,
    })),
  ),
};
const Component = mapping[entity];

export const LogoImg = ({ className }) => {
  return (
    <Suspense>
      <Component className={className} />
    </Suspense>
  );
};
