import React, { useRef, useState } from "react";
import { Alert, Button, Input, message } from "antd";
import Title from "antd/lib/typography/Title";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useZxing } from "react-zxing";
import styled, { css } from "styled-components";

import { useQrScannerContext } from "../Context";
import { StyledElement as BackgroundContainer } from "pages/SocialService/components/reusables";
import { getCitizen, redeemSocialService } from "services/citizen";
import { handleApiValidationErrors } from "utils/api";
import { ENTITIES } from "constant/entity";
import { VideoQrScannerHeader } from "./videoQrScannerHeader";
import { getCanRedeemAkapMaipProgram, getIsAkapMaipUser } from "utils/citizen";

const Container = styled(BackgroundContainer)`
  height: 100vh;
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 100%;

  background-image: none;
  padding-top: 0;
`;
const QrScanner = styled.video`
  width: 100%;
  height: calc(100% - 165px);
  object-fit: cover;
  position: absolute;
  top: 165px;
  z-index: -1;
`;

const LogoutButton = styled(Button)`
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
  z-index: 2;
`;

const Header = styled(Title)`
  color: white !important;
  margin: 0 !important;
`;

const overlayStyles = css`
  position: absolute;
  background-color: ${({ theme }) => {
    const { colors, entity } = theme;
    let color = colors.primary;

    switch (entity) {
      case ENTITIES.BPSF:
      case ENTITIES.TINGOG_47:
      case ENTITIES.MATIK_KANG_TINO:
        color = colors.secondary;
        break;
    }

    return `${color}90`;
  }};
`;
const TopOverlay = styled.div`
  ${overlayStyles}
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100vw;
`;

const BottomOverlay = styled.div`
  bottom: 0;
  ${overlayStyles}
  display: flex;
  flex-direction: column;
  height: 180px;
  padding-top: 25px;
  padding-left: 10%;
  padding-right: 10%;
  width: 100vw;
`;

const LeftOverlay = styled.div`
  ${overlayStyles}
  display: flex;
  top: 265px;
  left: 0;
  align-items: center;
  justify-content: center;
  height: calc(100% - 195px - 250px);
  width: 10%;
`;

const RightOverlay = styled.div`
  ${overlayStyles}
  top: 265px;
  right: 0;
  align-items: center;
  justify-content: center;
  height: calc(100% - 195px - 250px);
  width: 10%;
`;

const SearchButton = styled(Button)`
  z-index: 2;
  margin-top: 15px;
  border-radius: 10px;

  ${({ theme }) => {
    const { colors, entity } = theme;
    let color = colors.primary;
    let hoverColor = colors.primaryHover;
    let textColor = "#fff";

    if ([ENTITIES.AMPING_KANUNAY, ENTITIES.KAUBAN_NATO].includes(entity)) {
      color = colors.secondary;
      hoverColor = colors.secondaryHover;
    }

    if (entity === ENTITIES.AMPING_KANUNAY) {
      textColor = "#000";
    }

    return css`
      background: ${color};
      border-color: ${color};

      &:hover,
      &:focus {
        background: ${hoverColor};
        border-color: ${hoverColor};
      }
      span {
        color: ${textColor};
      }
    `;
  }}
`;

const IdSearchInput = styled(Input)`
  margin-top: 10px;
`;

const Overlay = ({ onRedeem }) => {
  const inputRef = useRef(null);

  return (
    <div style={{ zIndex: 5 }}>
      <TopOverlay>
        <Header level={3}>Scan QR Code</Header>
      </TopOverlay>
      <LeftOverlay />
      <RightOverlay />
      <BottomOverlay>
        <Header level={4}>Couldn&apos;t find Citizen?</Header>
        <Header level={5}>Enter ID number instead.</Header>
        <IdSearchInput ref={inputRef} placeholder="Enter ID Number" />
        <SearchButton
          onClick={() => {
            onRedeem(inputRef.current.input.value.trim());
          }}>
          Search Citizen
        </SearchButton>
      </BottomOverlay>
    </div>
  );
};

const VideoQrScanner = () => {
  const { logout, user } = useQrScannerContext();
  const [error, setError] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const onRedeem = async citizenId => {
    const isAkapMaipUser = getIsAkapMaipUser(user);
    if (isAkapMaipUser) {
      try {
        const citizen = await getCitizen(citizenId);
        if (!getCanRedeemAkapMaipProgram(citizen, user)) {
          messageApi.error({
            key: "quickRedeem",
            content: "Error! Citizen is not endorsed by the program.",
            style: {
              fontWeight: 500,
            },
          });
          return;
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (user?.overrides && user.overrides?.citizen_program?.program?.redemption_limit !== null && !isAkapMaipUser) {
      messageApi.loading({
        key: "quickRedeem",
        content: "Redeeming....",
        className: "quickRedeem",
        style: {
          fontWeight: 500,
        },
      });

      const citizenProgram = user?.overrides?.citizen_program;

      if (citizenProgram) {
        redeemSocialService({
          citizen: citizenId,
          agency_user: user?.id || null,
          agency: user?.agency?.id || null,
          overrides: citizenProgram?.overrides || {},
          program: citizenProgram.program.id,
          program_type: citizenProgram.program_type.id,
          proposed_amount: citizenProgram.proposed_amount,
        })
          .then(response => {
            if (messageApi.destroy) {
              messageApi.destroy("quickRedeem");
            }

            if (response.ok) {
              messageApi.success({
                key: "quickRedeem",
                content: "Successfully redeemed",
                icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
                style: {
                  fontWeight: 500,
                },
              });
            } else if (response.status === 400) {
              handleApiValidationErrors(response);
            }
          })
          .catch(error => {
            if (messageApi.destroy) {
              messageApi.destroy("quickRedeem");
            }

            messageApi.error({
              key: "quickRedeem",
              content: error.message,
              style: {
                fontWeight: 500,
              },
            });
          });
      } else {
        /* Navigate directly to citizen details page if overrides has no program */
        navigate(`/citizen/${citizenId}`);
      }
    } else {
      navigate(`/citizen/${citizenId}`);
    }
  };

  const { ref } = useZxing({
    onError: error => {
      setError({
        message: "Error",
        description: error.message,
      });
    },
    paused: !!error,
    timeBetweenDecodingAttempts: 2000,
    constraints: {
      video: {
        facingMode: "environment",
      },
    },
    onDecodeResult(result) {
      if (result) {
        if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(result.getText())) {
          onRedeem(result.getText());
        } else {
          setError({
            message: "Error",
            description: "Invalid QR Code",
          });
        }
      }
    },
  });

  return (
    <Container>
      <VideoQrScannerHeader />
      {contextHolder}
      <Overlay onRedeem={onRedeem} />
      <QrScanner ref={ref} />
      {error ? (
        <Alert
          message={error.message}
          description={error.description}
          type="error"
          closable
          onClose={() => setError(null)}
        />
      ) : null}
      <LogoutButton
        onClick={() => {
          logout();
        }}
        size="small">
        Logout
      </LogoutButton>
    </Container>
  );
};

export default VideoQrScanner;
