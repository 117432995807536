export const ENTITIES = {
  ABTIK_MO_SERBISYO: "abtikmoserbisyo",
  AKAP_BIGAS: "akapbigas",
  AMPING_KANUNAY: "ampingkanunay",
  BPSF: "bpsf",
  GWAPONG_SERBISYO: "gwapongserbisyo",
  KAUBAN_NATO: "kaubannato",
  MATIK_KANG_TINO: "matikkangtino",
  OYO_UY: "oyouy",
  SERBISYONG_EXPRESS: "serbisyongexpress",
  SERBISYONG_LAMI_KAAYO: "serbisyonglamikaayo",
  SERBISYONG_TINUD_ANAY: "serbisyongtinudanay",
  TINGOG_47: "tingog47",
};
export const entity = process.env.REACT_APP_ROOT_ENTITY || ENTITIES.BPSF;

export const ENTITIES_ID = {
  bpsf: 1,
  kaubannato: 2,
  ampingkanunay: 3,
  abtikmoserbisyo: 4,
  serbisyongtinudanay: 5,
  serbisyonglamikaayo: 6,
  akapbigas: 7,
  gwapongserbisyo: 8,
  oyouy: 9,
  serbisyongexpress: 10,
  tingog47: 11,
  matikkangtino: 12,
};
