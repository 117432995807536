import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { FormItem, InputNumberField, InputSelectField, InputTextFields } from "../reusables";
import { useSocialServiceContext } from "pages/SocialService/Context";
import { getProgramServiceProvider } from "services/citizen";
import { PROGRAMS } from "constant/program";
import { formatMoney } from "utils/money";
import styled from "styled-components";
import {
  BirthdateSelectContainer,
  InputDateSelectField,
  InputPhoneNumberField,
} from "pages/Register/components/InputField.styled";
import { daysOptions, maipAssistanceNeededOptions, monthsOptions, yearOptions } from "constant/selectConstants";
import moment from "moment";
import { formatPhoneNumber, parsePhoneNumber } from "utils/citizen";
import { Divider, Select } from "antd";
import { isEmptyString } from "utils";

const FormItemSmLabel = styled(FormItem)`
  .ant-form-item-label > label {
    font-size: 10px;
  }
`;

const MAIP = ({ form, selectedCode, setSelectedCode }) => {
  const { citizen } = useSocialServiceContext();
  const [serviceProviderOptions, setServiceProviderOptions] = useState([
    {
      label: "Others",
      value: "others",
    },
  ]);
  const [isShowOthers, setIsShowOthers] = useState(false);
  const [isShowOthersAssistance, setIsShowOthersAssistance] = useState(false);
  const maipCode = citizen?.codes?.find(code => code.program.id === PROGRAMS.MAIP);
  const [approvedAmount, setApprovedAmount] = useState(0);
  const [isRepresentative, setIsRepresentative] = useState(form.getFieldValue("overrides.is_representative"));
  const [birthDate, setBirthDate] = useState({
    year: "",
    month: "",
    day: "",
  });
  const balance = useMemo(() => (maipCode?.remaining_balance || 0) - approvedAmount, [maipCode, approvedAmount]);
  const sponsorOptions = useMemo(
    () =>
      citizen?.codes?.reduce((acc, code) => {
        if (code.program.id === PROGRAMS.MAIP) {
          acc.push({
            ...code,
            label: isEmptyString(code.user_fullname) ? code.id : code.user_fullname,
            value: code.id,
          });
        }
        return acc;
      }, []) || [],
    [citizen?.codes],
  );

  const getServiceProviders = async () => {
    try {
      const response = await getProgramServiceProvider();

      if (response.results) {
        const sortedOptions = response.results
          .sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0; // names are equal
          })
          .map(item => ({ label: item.name, value: item.id }));
        setServiceProviderOptions(sortedOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ "overrides.balance": balance });
  }, [balance]);

  useEffect(() => {
    const formattedBirthDate = moment(
      `${birthDate.year}-${birthDate.month}-${birthDate.year.day}`,
      "YYYY-MM-DD",
      false,
    ).format("YYYY-MM-DD");
    if (formattedBirthDate !== "Invalid date") {
      form.setFieldsValue({
        "overrides.beneficiary_birth_date": formattedBirthDate,
      });
    }
  }, [birthDate]);

  useEffect(() => {
    getServiceProviders();
  }, []);

  useEffect(() => {
    if (sponsorOptions.length && !selectedCode) {
      setSelectedCode(sponsorOptions[0]);
      form.setFieldsValue({ "overrides.principal_sponsor": sponsorOptions[0].value });
    }
  }, [selectedCode, sponsorOptions]);

  if (!sponsorOptions.length) {
    return null;
  }

  if (!maipCode) {
    return null;
  }

  return (
    <>
      <FormItem
        label="Principal Sponsor"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.principal_sponsor"
        rules={[
          {
            required: true,
            message: "This is required",
          },
        ]}>
        <InputSelectField
          showSearch
          options={sponsorOptions}
          required
          onChange={(value, option) => {
            setSelectedCode(option);
          }}
        />
      </FormItem>
      <FormItem
        label="Redemption Limit"
        wrapperCol={{
          span: 24,
        }}>
        <InputNumberField
          style={{ width: "100%" }}
          placeholder={"Input Amount"}
          formatter={() => formatMoney(selectedCode?.remaining_balance || 0)}
          readOnly
          value={selectedCode?.remaining_balance || 0}
        />
      </FormItem>
      {/*  */}
      <FormItemSmLabel
        label="Is the one scanned the Representative or Beneficiary?"
        wrapperCol={{
          span: 24,
        }}
        name="scanner_type"
        rules={[
          {
            required: true,
            message: "This is required",
          },
        ]}>
        <InputSelectField
          showSearch
          options={[
            {
              label: "Representative",
              value: "representative",
            },
            {
              label: "Beneficiary",
              value: "beneficiary",
            },
          ]}
          required
          onChange={value => {
            const isRep = value === "representative";
            form.setFieldsValue({
              "overrides.is_representative": isRep,
              "overrides.is_beneficiary": !isRep,
            });
            setIsRepresentative(isRep);
          }}
        />
      </FormItemSmLabel>
      {isRepresentative ? (
        <>
          <h4>Beneficiary Details</h4>
          <FormItem
            label="Full Name"
            wrapperCol={{
              span: 24,
            }}
            name="overrides.beneficiary_full_name"
            rules={[
              {
                required: isRepresentative,
                message: "Full Name is required",
              },
            ]}>
            <InputTextFields />
          </FormItem>

          <FormItem
            label="Birthdate"
            name="overrides.beneficiary_birth_date"
            rules={[{ required: true, message: "Birthdate is required." }]}>
            <BirthdateSelectContainer>
              <FormItem className="personal-info-date-select" label="Year">
                <InputDateSelectField
                  options={yearOptions()}
                  showSearch
                  allowClear
                  onChange={value => {
                    setBirthDate(prev => ({ ...prev, year: value }));
                  }}
                />
              </FormItem>
              <FormItem className="personal-info-date-select" label="Month">
                <InputDateSelectField
                  options={monthsOptions()}
                  filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                  showSearch
                  allowClear
                  onChange={value => {
                    setBirthDate(prev => ({ ...prev, month: value }));
                  }}
                />
              </FormItem>
              <FormItem className="personal-info-date-select" label="Date">
                <InputDateSelectField
                  options={daysOptions(birthDate.month, birthDate.year)}
                  showSearch
                  allowClear
                  onChange={value => {
                    setBirthDate(prev => ({ ...prev, day: value }));
                  }}
                />
              </FormItem>
            </BirthdateSelectContainer>
          </FormItem>

          <FormItem
            label="Mobile Number"
            name="phone_number"
            rules={[
              { required: true, message: "Mobile number is required" },
              {
                validator: async (_, value) => {
                  const regex = /^9\d{9}$/;
                  if (!regex.test(value)) {
                    return Promise.reject(
                      "Please enter a valid Philippine mobile number (10 digits, starting with '639').",
                    );
                  }
                  return Promise.resolve();
                },
                message: "Please enter a valid Philippine mobile number (10 digits, starting with '639').",
              },
            ]}>
            <InputPhoneNumberField
              name="phone_number"
              autoComplete="off"
              formatter={formatPhoneNumber}
              parser={parsePhoneNumber}
              onChange={value => {
                form.setFieldsValue({
                  "overrides.beneficiary_phone_number": value,
                });
              }}
              onKeyPress={event => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              defaultValue={63}
              maxLength={12}
              minLength={12}
            />
          </FormItem>
          <Divider />
        </>
      ) : null}
      {/*  */}
      <FormItem
        label="Diagnosis"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.diagnosis"
        rules={[
          {
            required: true,
            message: "Diagnosis is required",
          },
        ]}>
        <InputTextFields />
      </FormItem>
      <FormItem
        name="overrides.assistance_needed"
        label="Assistance Needed"
        rules={[{ required: true, message: "Assistance needed is required", type: "array" }]}>
        <InputSelectField
          mode="multiple"
          placeholder="Please select assistance needed"
          onChange={value => {
            setIsShowOthersAssistance(value.includes("Others"));
          }}>
          {maipAssistanceNeededOptions.map((option, index) => (
            <Select.Option value={option} key={index.toString()}>
              {option}
            </Select.Option>
          ))}
        </InputSelectField>
      </FormItem>
      {isShowOthersAssistance ? (
        <FormItem
          label="Please specify other assistance needed"
          wrapperCol={{
            span: 24,
          }}
          name="overrides.assistance_needed_others"
          rules={[
            {
              required: isShowOthersAssistance,
              message: "Assistance needed is required",
            },
          ]}>
          <InputTextFields />
        </FormItem>
      ) : null}
      <FormItem
        label="Service Provider (Clinic / Hospitals etc.)"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.service_provider"
        rules={[
          {
            required: true,
            message: "Service Provider is required",
          },
        ]}>
        <InputSelectField
          showSearch
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          options={serviceProviderOptions}
          placeholder={"Service Provider (Clinic / Hospitals etc.)"}
          required
          onChange={(value, option) => {
            form.setFieldsValue({ "overrides.service_provider_name": option.label });
            setIsShowOthers(value === "others");
          }}
        />
      </FormItem>
      <div style={{ display: "none" }}>
        <FormItem name="overrides.service_provider_name">
          <></>
        </FormItem>
      </div>
      {isShowOthers ? (
        <FormItem
          label="Please specify"
          wrapperCol={{
            span: 24,
          }}
          name="overrides.service_provider_others"
          rules={[
            {
              required: isShowOthers,
              message: "Service Provider is required",
            },
          ]}>
          <InputTextFields />
        </FormItem>
      ) : null}
      <FormItem
        label="MAIP Service Code"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.service_code">
        <InputTextFields />
      </FormItem>
      <FormItem
        label="Requested Amount"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.requested_amount"
        rules={[
          {
            required: true,
            message: "Requested Amount is required",
          },
        ]}>
        <InputNumberField
          style={{ width: "100%" }}
          placeholder={"Input Amount"}
          formatter={(value, info) => {
            return !info.userTyping ? formatMoney(value) : value;
          }}
          onFocus={event => {
            event.target.select();
          }}
        />
      </FormItem>
      <FormItem
        label="Approved Amount"
        wrapperCol={{
          span: 24,
        }}
        name="proposed_amount"
        rules={[
          {
            required: true,
            message: "Approved Amount is required",
          },
        ]}>
        <InputNumberField
          style={{ width: "100%" }}
          placeholder={"Input Amount"}
          formatter={(value, info) => {
            return !info.userTyping ? formatMoney(value) : value;
          }}
          onChange={value => {
            setApprovedAmount(value);
          }}
          onFocus={event => {
            event.target.select();
          }}
        />
      </FormItem>
      <FormItem
        label="Balance after Approved Amount"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.balance"
        rules={[
          {
            required: true,
            message: "Approved Amount is required",
          },
          {
            validator: (_, value) => {
              if (value === undefined || value === "") {
                return Promise.resolve();
              }

              if (parseFloat(value) < 0) {
                return Promise.reject(new Error("Insufficient balance"));
              }

              return Promise.resolve();
            },
          },
        ]}>
        <InputNumberField
          prefix="₱"
          style={{ width: "100%" }}
          placeholder={"Input Amount"}
          formatter={value => formatMoney(value)}
          readOnly
        />
      </FormItem>
    </>
  );
};
export default MAIP;
