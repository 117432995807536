import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { getAgencies, getAgencyPrograms } from "services/meta";
import { useNavigate } from "react-router-dom";
import { getProgramTypes, redeemSocialService, socialServiceTransaction } from "services/citizen";
import { useSocialServiceContext } from "../Context";
import TextArea from "antd/lib/input/TextArea";
import StyledButton from "components/button/Button";
import styled from "styled-components";
import { useQrScannerContext } from "pages/QRScan/Context";
import {
  FormItem,
  InputSelectField,
  InputNumberField,
  InputTextFields,
} from "pages/SocialService/components/reusables";
import MAIP from "./forms/Maip";
import AkapRice from "./forms/AkapRice";
import Swal from "sweetalert2";
import { PROGRAM_TYPES, PROGRAMS } from "constant/program";
import { formatMoney } from "utils/money";
import { getCanRedeemAkapMaipProgram, getIsAkapMaipUser } from "utils/citizen";

const RedeemButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.primary};
`;

const RedeemForm = () => {
  const navigate = useNavigate();
  const [agency, setAgency] = useState([]);
  const [agencyPrograms, setAgencyPrograms] = useState([]);
  const [programTypes, setProgramTypes] = useState([]);
  const { citizen } = useSocialServiceContext();
  const { user: agencyUser } = useQrScannerContext();
  const [selectedCode, setSelectedCode] = useState(null);

  const akapRiceCode = citizen?.codes?.find(code => code.program.id === PROGRAMS.AKAP);

  const [form] = Form.useForm();

  const [selectedAgency, setSelectedAgency] = useState();
  const [selectedProgram, setSelectedProgram] = useState();
  const [selectedProgramType, setSelectedProgramType] = useState();

  const [disabled, setDisabled] = useState({
    agency: false,
    program: false,
    program_type: false,
  });

  const onFinish = async values => {
    try {
      // check if agency user is AKAP or MAIP and Citizen is endorsed
      const isAkapMaipUser = getIsAkapMaipUser(agencyUser);
      if (isAkapMaipUser) {
        if (!getCanRedeemAkapMaipProgram(citizen, agencyUser)) {
          throw new Error("Citizen is not endorsed by the program.");
        }
      }

      const { proposed_amount, agency, program, program_type } = values;
      const subsidizedAmount = values["overrides.subsidized_amount"];
      const overrides = Object.entries(values).reduce((acc, [key, value]) => {
        if (key.includes("overrides.")) {
          const overridesItemKey = key.split(".")[1];
          acc[overridesItemKey] = value;
        }
        return acc;
      }, {});

      const response = await redeemSocialService({
        citizen: citizen.id,
        proposed_amount: proposed_amount || subsidizedAmount || 0,
        agency,
        program,
        program_type,
        overrides,
        agency_user: agencyUser?.id || null,
      });
      const data = await response.json();

      if (data.id && [PROGRAM_TYPES.FINANCIAL, PROGRAM_TYPES.SUBSIDY].includes(program_type) && isAkapMaipUser) {
        await socialServiceTransaction({
          amount: proposed_amount || subsidizedAmount,
          citizen_program: data.id,
          ...(selectedCode?.id ? { code: selectedCode.id } : {}),
        });
      }
      navigate(-1);
    } catch (error) {
      Swal.fire({
        title: "Oops!",
        text: error.toString(),
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: "Exit",
        showConfirmButton: false,
        width: "80%",
      });
    }
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    getAgencies().then(agency => setAgency(agency?.results.map(({ name, id }) => ({ value: id, label: name }))));
  }, []);

  useEffect(() => {
    const disabledFields = {
      agency: false,
      program: false,
      program_type: false,
    };

    if (selectedAgency) {
      getAgencyPrograms({ id: selectedAgency })
        .then(programs => {
          const agencyPrograms = programs?.results || [];

          setAgencyPrograms(agencyPrograms.map(({ name, id }) => ({ value: id, label: name })));

          const selectProgram = agencyUser?.overrides?.citizen_program?.program?.id;
          if (selectProgram) {
            form.setFieldsValue({
              program: selectProgram,
            });
            setSelectedProgram(selectProgram);
          }

          const selectProgramType = agencyUser?.overrides?.citizen_program?.program_type?.id;
          if (selectProgramType) {
            form.setFieldsValue({
              program_type: selectProgramType,
            });
            setSelectedProgramType(selectProgramType);
          }

          if (
            ((selectedAgency === 2 && selectProgram === PROGRAMS.AKAP) || // DSWD + AKAP
              (selectedAgency === 1 && selectProgram === PROGRAMS.MAIP)) && // DOH + MAIP
            selectProgramType
          ) {
            disabledFields.agency = true;
            disabledFields.program = true;
            disabledFields.program_type = true;

            if (selectedAgency === 1 && selectProgram === PROGRAMS.MAIP) {
              form.setFieldsValue({
                scanner_type: "representative",
                "overrides.is_beneficiary": false,
                "overrides.is_representative": true,
              });
            }
          }

          // sets the only program for agency if only have one program
          if (agencyPrograms && agencyPrograms.length < 3) {
            const program = agencyPrograms.filter(({ id }) => id !== 110)[0]?.id;
            form.setFieldsValue({
              program,
            });

            setSelectedProgram(program);
            if (program) {
              disabledFields.program = true;
            }
          }
        })
        .then(() => {
          getProgramTypes().then(programTypes => {
            setProgramTypes(programTypes?.results.map(({ name, id }) => ({ value: id, label: name })));

            // special case for bpsf
            if (selectedAgency === 34) {
              form.setFieldsValue({
                program_type: 3,
              });

              setSelectedProgramType(3);
              disabledFields.program_type = true;
            }
          });
        })
        .finally(() => {
          setDisabled(disabledFields);
        });
    }
  }, [selectedAgency]);

  useEffect(() => {
    const agencyId = agencyUser.agency?.id;
    form.setFieldsValue({ agency: agencyId });
    setSelectedAgency(agencyId);
  }, [agencyUser]);

  const handleValuesChange = (changedValues, allValues) => {
    const total_kilos = allValues["overrides.total_kilos"];
    const price_per_kilo = allValues["overrides.price_per_kilo"];

    const remaining_balance = akapRiceCode?.remaining_balance || 0;

    // Calculate Gross Total
    const grossTotal = total_kilos && price_per_kilo ? total_kilos * price_per_kilo : 0;

    // Calculate Subsidized Amount (Derived)
    let subsidizedAmount = grossTotal * 0.5;

    // Ensure the subsidized amount is not negative after adjustment
    subsidizedAmount = Math.max(subsidizedAmount, 0);

    // Calculate Balance After Payment
    const balanceAfterPayment = remaining_balance - subsidizedAmount;

    // Set the computed values in the form
    form.setFieldsValue({
      "overrides.gross_total": grossTotal,
      "overrides.subsidized_amount": subsidizedAmount,
      "overrides.balance_after_payment": balanceAfterPayment,
    });
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 24,
      }}
      style={{
        minWidth: "90%",
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={handleValuesChange}
      autoComplete="off">
      <FormItem
        label="Agency"
        name="agency"
        wrapperCol={{
          span: 24,
        }}
        rules={[
          {
            required: true,
            message: "Agency is required",
          },
        ]}>
        <InputSelectField
          label="Agency"
          showSearch
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          onChange={value => {
            setSelectedAgency(value);

            form.setFieldsValue({ program: undefined });
            setSelectedProgram(undefined);
          }}
          options={agency}
          placeholder={"Select Agency"}
          disabled={disabled.agency}
        />
      </FormItem>

      {selectedAgency === 33 ? (
        <FormItem
          label="Agency Name"
          wrapperCol={{
            span: 24,
          }}
          name="overrides.agency"
          rules={[
            {
              required: true,
              message: "Agency Name is required",
            },
          ]}>
          <Input style={{ width: "100%" }} placeholder={"Enter Agency Name"} />
        </FormItem>
      ) : null}

      <FormItem
        label="Program"
        wrapperCol={{
          span: 24,
        }}
        name="program"
        dependencies={["agency"]}
        rules={[
          {
            required: true,
            message: "Program is required",
          },
        ]}>
        <InputSelectField
          showSearch
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          value={selectedProgram}
          onChange={value => {
            setSelectedProgram(value);
          }}
          options={agencyPrograms}
          placeholder={"Select Program"}
          disabled={disabled.program}
        />
      </FormItem>

      {selectedProgram === 110 ? (
        <FormItem
          label="Social Service"
          wrapperCol={{
            span: 24,
          }}
          name="overrides.program"
          dependencies={["agency", "overrides.agency"]}
          rules={[
            {
              required: true,
              message: "Program Name is required",
            },
          ]}>
          <InputTextFields style={{ width: "100%" }} placeholder={"Enter Social Service"} />
        </FormItem>
      ) : null}

      <FormItem
        label="Program Type"
        wrapperCol={{
          span: 24,
        }}
        name="program_type"
        dependencies={["agency"]}
        rules={[
          {
            required: true,
            message: "Program Type is required",
          },
        ]}>
        <InputSelectField
          showSearch
          value={selectedProgramType}
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          onChange={value => {
            setSelectedProgramType(value);
          }}
          options={programTypes}
          placeholder={"Select Program Type"}
          disabled={disabled.program_type}
        />
      </FormItem>

      {selectedProgramType === 1 && ![PROGRAMS.MAIP, PROGRAMS.AKAP].includes(selectedProgram) ? (
        <FormItem
          label="Proposed Amount"
          wrapperCol={{
            span: 24,
          }}
          name="proposed_amount"
          dependencies={["agency", "program"]}
          rules={[
            {
              required: true,
              message: "Proposed Amount is required",
            },
          ]}>
          <InputNumberField
            prefix="₱"
            style={{ width: "100%" }}
            disabled={!selectedProgram}
            placeholder={"Input Proposed Amount"}
            formatter={value => formatMoney(value)}
          />
        </FormItem>
      ) : null}

      {selectedProgramType === 2 ? (
        <FormItem
          label="Details"
          wrapperCol={{
            span: 24,
          }}
          name="overrides.details"
          dependencies={["agency", "program"]}
          rules={[
            {
              required: true,
              message: "Details is required",
            },
          ]}>
          <TextArea placeholder="Enter Details" autoSize />
        </FormItem>
      ) : null}

      {selectedProgram === PROGRAMS.AKAP ? ( // AKAP
        <AkapRice
          form={form}
          selectedProgram={selectedProgram}
          selectedCode={selectedCode}
          setSelectedCode={setSelectedCode}
        />
      ) : null}
      {selectedProgram === PROGRAMS.MAIP ? ( // MAIP
        <MAIP form={form} selectedCode={selectedCode} setSelectedCode={setSelectedCode} />
      ) : null}

      <Form.Item>
        <RedeemButton type="primary" htmlType="submit">
          Redeem
        </RedeemButton>
      </Form.Item>
    </Form>
  );
};

export default RedeemForm;
