import React, { Suspense, lazy } from "react";

import { ENTITIES, entity } from "constant/entity";

const mapping = {
  [ENTITIES.AMPING_KANUNAY]: lazy(() =>
    import("./AmpingKanunay").then(module => ({
      default: module.AmpingKanunay,
    })),
  ),
  [ENTITIES.KAUBAN_NATO]: lazy(() =>
    import("components/kaubanNato/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.BPSF]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.SERBISYONG_TINUD_ANAY]: lazy(() =>
    import("components/serbisyongTinudanay/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.SERBISYONG_LAMI_KAAYO]: lazy(() =>
    import("components/serbisyongLamiKaayo/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.TINGOG_47]: lazy(() =>
    import("components/tingog47/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.SERBISYONG_EXPRESS]: lazy(() =>
    import("components/serbisyongExpress/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.OYO_UY]: lazy(() =>
    import("components/oyoUy/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.MATIK_KANG_TINO]: lazy(() =>
    import("components/matikkangtino/homeLayout").then(module => ({
      default: module.HomeLayout,
    })),
  ),
  [ENTITIES.GWAPONG_SERBISYO]: lazy(() =>
    import("./GwapongSerbisyo").then(module => ({
      default: module.GwapongSerbisyo,
    })),
  ),
};
const Component = mapping[entity];

export const LoginFormContainer = ({ children }) => {
  return (
    <Suspense>
      <Component>{children}</Component>
    </Suspense>
  );
};
