import React, { useEffect, useMemo } from "react";
import { FormItem, InputNumberField } from "../reusables";
import { useSocialServiceContext } from "pages/SocialService/Context";
import { PROGRAMS } from "constant/program";
import { formatMoney } from "utils/money";
import { isEmptyString } from "utils";

const AkapRice = ({ form, selectedCode, setSelectedCode }) => {
  const { citizen } = useSocialServiceContext();
  const akapRiceCode = citizen?.codes?.find(code => code.program.id === PROGRAMS.AKAP);

  useEffect(() => {
    form.setFieldsValue({
      gross_total: 0,
      subsidized_amount: 0,
      min_customer_payment: 0,
      customer_payment: 0,
      balance_after_payment: 0,
    });
  }, [form]);

  const sponsorOptions = useMemo(
    () =>
      citizen?.codes?.reduce((acc, code) => {
        if (code.program.id === PROGRAMS.AKAP) {
          acc.push({
            ...code,
            label: isEmptyString(code.user_fullname) ? code.id : code.user_fullname,
            value: code.id,
          });
        }
        return acc;
      }, []) || [],
    [citizen?.codes],
  );

  useEffect(() => {
    if (sponsorOptions.length && !selectedCode) {
      setSelectedCode(sponsorOptions[0]);
    }
  }, [selectedCode, sponsorOptions]);

  if (!sponsorOptions.length) {
    return null;
  }

  if (!akapRiceCode) {
    return null;
  }

  return (
    <>
      <FormItem
        label="Price Per Kilo"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.price_per_kilo"
        dependencies={["agency", "program", "overrides.total_kilos", "overrides.balance_after_payment"]}
        rules={[
          {
            required: true,
            message: "Price per kilo is required",
          },
        ]}>
        <InputNumberField
          prefix="₱"
          style={{ width: "100%" }}
          placeholder={"Price Per Kilo"}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        />
      </FormItem>
      <FormItem
        label="Total Kilos"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.total_kilos"
        dependencies={["agency", "program", "overrides.price_per_kilo", "overrides.balance_after_payment"]}
        rules={[
          {
            required: true,
            message: "Total kilo is required",
          },
          {
            validator: (_, totalKilos) => {
              const pricePerKilo = form.getFieldValue("overrides.price_per_kilo");
              const balance = akapRiceCode?.remaining_balance || 0;
              const maxKilos = Math.floor(balance / (pricePerKilo / 2));

              if (totalKilos === undefined || totalKilos === "") {
                return Promise.resolve();
              }

              if (parseFloat(totalKilos) > maxKilos) {
                return Promise.reject(new Error(`Max. Kilos: ${maxKilos}`));
              }

              return Promise.resolve();
            },
          },
        ]}>
        <InputNumberField
          style={{ width: "100%" }}
          placeholder={"Total Kilo"}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        />
      </FormItem>
      <FormItem
        label="Gross Total"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.gross_total"
        dependencies={["agency", "program"]}>
        <InputNumberField
          style={{ width: "100%" }}
          disabled
          placeholder={"Gross Total"}
          formatter={value => formatMoney(value)}
        />
      </FormItem>
      <FormItem
        label="Subsidized Amount (Derived)"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.subsidized_amount"
        dependencies={["agency", "program"]}>
        <InputNumberField
          style={{ width: "100%" }}
          disabled
          placeholder={"Subsidized Amount (Derived)"}
          formatter={value =>formatMoney(value)}
        />
      </FormItem>
      <FormItem
        label="Balance after Payment"
        wrapperCol={{
          span: 24,
        }}
        name="overrides.balance_after_payment"
        dependencies={["agency", "program", "overrides.price_per_kilo", "overrides.total_kilos"]}
        rules={[
          {
            validator: (_, value) => {
              if (value === undefined || value === "") {
                return Promise.resolve();
              }

              if (parseFloat(value) < 0) {
                return Promise.reject(new Error("Insufficient balance"));
              }

              return Promise.resolve();
            },
          },
        ]}>
        <InputNumberField
          style={{ width: "100%" }}
          disabled
          placeholder={"Balance after Payment"}
          formatter={value =>formatMoney(value)}
        />
      </FormItem>
    </>
  );
};

export default AkapRice;
