export const PROGRAMS = {
  AKAP: 383,
  MAIP: 382,
};

export const PROGRAM_TYPES = {
  FINANCIAL: 1,
  IN_KIND: 2,
  ATTENDANCE: 3,
  SUBSIDY: 4,
}
