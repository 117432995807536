import React, { useContext, useState } from "react";
import { RegisterContainer } from "../components/Register.styled";
import WizardForm from "../../../components/form/wizard";
import PersonalInformation from "./steps/PersonalInformation";
import PersonalAddress from "./steps/PersonalAddress";
import OTPVerification from "./steps/OTPVerification";
import UploadImage from "./steps/UploadImage";
import IDCard from "./steps/IDCard";
import { NewCitizenContext } from "./NewCitizenContext";
import Summary from "./steps/Summary";
import { RegistrationHeader } from "../components/registrationHeader";
import { CitizenDetailHeader } from "pages/SocialService/components/citizenDetailHeader";
import styled, { css } from "styled-components";
import { desktop } from "utils/styles";

const headerStyle = css`
  ${desktop`
    * {
      display: none !important;
    }
    display: none !important;
    > img {
      display: none !important;
    }
  `}
`;
const StyledCitizenDetailHeader = styled(CitizenDetailHeader)`
  ${headerStyle}
`;
const StyledRegistrationHeader = styled(RegistrationHeader)`
  ${headerStyle}
`;

const NewCitizensComponent = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { checkUserExist, sendOTPMobile, verifyOTP, verifyPhoto, registerCitizenDetails } =
    useContext(NewCitizenContext);
  const isIdCardStep = currentStep === 5;

  const steps = [
    {
      title: "Personal Information",
      component: PersonalInformation,
      hideBackBtn: true,
      asyncValidator: checkUserExist,
    },
    {
      title: "Address",
      component: PersonalAddress,
      asyncFn: sendOTPMobile,
    },
    {
      title: "Verification",
      centerTitle: true,
      component: OTPVerification,
      hideBackBtn: true,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: verifyOTP,
    },
    {
      title: "Upload Image",
      centerTitle: true,
      component: UploadImage,
      hideBackBtn: true,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: verifyPhoto,
    },
    {
      title: "Summary",
      hideTitle: true,
      component: Summary,
      hideBackBtn: true,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: registerCitizenDetails,
    },
    {
      title: "Download ID Card",
      centerTitle: true,
      component: IDCard,
      hideBackBtn: true,
      hideWizardSteps: true,
      hideNextBtn: true,
    },
  ];

  return (
    <RegisterContainer>
      {isIdCardStep ? <StyledCitizenDetailHeader /> : <StyledRegistrationHeader />}
      <WizardForm isIdCardStep={isIdCardStep} steps={steps} setCurrentStep={setCurrentStep} />
    </RegisterContainer>
  );
};

export default NewCitizensComponent;
