import React, { useState } from "react";
import { Alert, Form } from "antd";
import styled, { css } from "styled-components";

import { FormItem, InputTextFields } from "pages/Register/components/InputField.styled";
import StyledButton from "components/button/Button";
import { LoginFormContainer } from "./loginFormContainer";

import { useQrScannerContext } from "../Context";

import { getAgencyCode } from "services/meta";
import { ENTITIES } from "constant/entity";

const Button = styled(StyledButton)`
  ${({ theme }) => {
    const { entity, colors } = theme;
    let color = colors.secondary;
    let hoverColor = colors.secondaryHover;

    switch (entity) {
      case ENTITIES.AMPING_KANUNAY:
        color = colors.primaryDark;
        hoverColor = colors.primaryDarkHover;
        break;
      case ENTITIES.SERBISYONG_TINUD_ANAY:
      case ENTITIES.SERBISYONG_LAMI_KAAYO:
      case ENTITIES.TINGOG_47:
        color = colors.primary;
        hoverColor = colors.primaryHover;
        break;
    }

    return css`
      background-color: ${color};
      border-color: ${color};

      &:hover,
      &:focus {
        background-color: ${hoverColor};
        border-color: ${hoverColor};
      }
    `;
  }};
`;

const LoginForm = () => {
  const [form] = Form.useForm();
  const { login } = useQrScannerContext();
  const [error, setError] = useState("");

  const onFinish = async values => {
    try {
      const response = await getAgencyCode(values);
      login(response);
    } catch (error) {
      setError(error?.detail || "User not found");
      console.warn("error", error);
    }
  };

  const onFinishFailed = errorInfo => {
    console.error("Failed:", errorInfo);
  };

  return (
    <LoginFormContainer>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          minWidth: "90%",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <FormItem
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}>
          <InputTextFields
            name="name"
            onChange={e =>
              form.setFieldsValue(values => ({
                ...values,
                name: e.target.value,
              }))
            }
          />
        </FormItem>

        <FormItem
          wrapperCol={{
            span: 24,
          }}
          name="code"
          label="Agency Code"
          rules={[
            {
              required: true,
              message: "Agency Code is required",
            },
          ]}>
          <InputTextFields
            name="code"
            onChange={e =>
              form.setFieldsValue(values => ({
                ...values,
                code: e.target.value,
              }))
            }
          />
        </FormItem>

        {error && (
          <Form.Item>
            <Alert description={error} type="error" closable onClose={() => setError("")} />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Login
          </Button>
        </Form.Item>
      </Form>
    </LoginFormContainer>
  );
};
export default LoginForm;
